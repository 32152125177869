// vars.scss
//
// Only define SCSS variables in this file, don't declare any actual style rules.

@import '../../../../shared/assets/styles/settings/vars';

$iphone-5-width: 375px;

$card-page-max-width: 462px;

$fonts-primary: HelveticaNeue, Helvetica, Arial, sans-serif;
$fonts-headings: HelveticaNeue, Helvetica, Arial, sans-serif;

// buttons.scss
//
// Sensible defaults for buttons. Only put button styles here that should apply to the large majority of use-cases.
// Unless it really doesn't make sense, try to follow the naming conventions and abstractions that Bootstrap uses.

button,
.button {
  font-family: $fonts-headings;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 4px;
  text-transform: uppercase;
  text-align: center;

  color: #000;
  background-color: transparent;
  // Enforce consistent sizing with buttons that do have a border
  border: 1px solid transparent;
  border-radius: 3px;

  // Extra padding on top due to Theinhardt high vertical alignment.
  padding: 11px 16px 7px;

  cursor: pointer;

  outline: none;

  &.inactive {
    opacity: .25;
  }

  &[disabled] {
    cursor: default;
    opacity: .5;
  }

  &:hover {
    opacity: .75;

    &:active {
      opacity: 1;
    }
  }
}

.button-upsell {
  color: #fff;
  background-color: $color-blue;
}

.button-secondary {
  background-color: $color-gray-7;
  color: #fff;
}

.button-outline-secondary {
  background-color: #fff;
  color: $color-gray-7;
  border: 1px solid $color-gray-7;
}

.button-block {
  display: block;
  width: 100%;
}

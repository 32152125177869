// spinner-ring.scss
//
// Circle that just spins round and round.

.spinner-ring {
  position: relative;
  font-size: 10px;
}

.spinner-ring,
.spinner-ring::after {
  width: 1.6em;
  height: 1.6em;
}

// to be used in an ::after pseudo-element to create a circular spinning element
@mixin spinner($spinner-thickness, $spinner-main-color, $spinner-secondary-color) {
  content: ' ';

  position: absolute;
  top: -$spinner-thickness;
  left: -$spinner-thickness;

  border-radius: 50%;
  border: $spinner-thickness solid $spinner-secondary-color;
  //
  border-right-color: $spinner-main-color;

  transform: translateX(-50%) translateY(-50%) translateZ(0);
  animation: spinner-ring .6s infinite linear;
}

.spinner-ring::after {
  @include spinner(.2em, $color-blue, $color-gray-4);
}

@keyframes spinner-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

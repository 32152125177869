// utility.scss
//
// Reusable widget styles that would have their own component if they required any interaction logic.

.invisible {
  opacity: 0;
}

.text-danger {
  color: $color-danger;
}

.text-success {
  color: $color-success;
}

.capitalize::first-letter {
  text-transform: uppercase;
}

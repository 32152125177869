// card-page.scss
//
// Responsive page layout that appears as a centered card on desktop and full screen on mobile

@import '../../../../shared/assets/styles/modules/card-page';

.card-page {
  .card {
    h2 {
      margin-bottom: 40px;
    }
  }
}

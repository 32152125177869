// main.scss
//
// Entry point for our global CSS. Don't define any CSS here, just use it to manage import order (and therefore, rule precedence).

// http://stackoverflow.com/a/39535907
@import '~normalize.css';

// Importing vars here will make them available for anything that follows (no need to import again).
@import 'settings/vars';
@import 'settings/fonts';

// Essentially CSS "components" - but use the name "modules" to avoid confusion with Angular components.
@import 'modules/defaults';
@import 'modules/utility';
@import 'modules/icons';
@import 'modules/buttons';
@import 'modules/forms';
@import 'modules/card-page';
@import 'modules/spinner-ring';

@import '../../../../shared/assets/styles/modules/forms';

// forms.scss
//
// Sensible defaults for common form elements. Only put form styles here that should apply to the large majority of use-cases.
// Unless it really doesn't make sense, try to follow the naming conventions and abstractions that Bootstrap uses.

.form-control {
  border-bottom: 1px solid #000;
  padding: 8px 2px;
  margin-bottom: 8px;

  &:focus {
    border-color: $color-blue;
  }
}

.has-error {
  .form-control-label {
    color: $color-gray-7;
  }

  .form-control {
    &:focus {
      border-color: $color-danger;
    }
  }
}

// fonts.scss
//
// @font-face rules.

@font-face {
  font-family: HelveticaNeue;
  font-weight: 300;
  src: local('HelveticaNeue-Light');
}

@font-face {
  font-family: HelveticaNeue;
  font-weight: 400;
  src: local('HelveticaNeue');
}

@font-face {
  font-family: HelveticaNeue;
  font-weight: 700;
  src: local('HelveticaNeue-Bold');
}

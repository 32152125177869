// defaults.scss
//
// Sensible defaults for standard HTML elements.

@import '../../../../shared/assets/styles/modules/defaults';

body {
  color: $color-gray-7;
  font-size: 20px;
  line-height: 1.2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: 0;
  color: #000;
}

h2 {
  font-family: $fonts-headings;
  letter-spacing: 5px;
  font-size: 20px;
}

h3 {
  font-size: 24px;
  font-weight: normal;
  text-transform: none;
}

a {
  color: $color-blue;

  &:hover {
    text-decoration: underline;
  }
}

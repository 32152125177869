@mixin icon($url, $width, $height) {
  width: $width;
  height: $height;
  background-image: url($url);
  background-size: $width $height;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}

.icon-check {
  @include icon('../../images/check.svg', 37px, 29px);
}

.icon-check-blue {
  @include icon('../../../../shared/assets/images/check-blue.svg', 37px, 29px);
}

.icon-chevron-right {
  @include icon('../../images/chevron-right.svg', 14px, 24px);
}

.icon-chevron-left {
  @include icon('../../images/chevron-left.svg', 14px, 24px);
}
